.navbar{
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed !important;
    top:0;
    left: 0;
    z-index: 999;
    background-color: transparent;
    transition: all 200ms ease-out;
}
.navbar > .container{
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 32px; */
}
/* .navbar > .container > .logo-wrap > img{
    width: 410.7px;
} */
.nav-menu{
    flex:1;
    margin-left: 48px;
}
.nav-menu > a{
    margin-right: 32px;
    color: var(--white-2);
}
.nav-menu > a.active{
    color: var(--white-1);
}
.nav-menu > a:last-child{
    margin-right: unset;
}
.nav-social > a,
.nav-social > button{
    margin-left: 20px;
}
.nav-social > a:first-child{
    margin-left: unset;
}
.nav-social > a > img,
.nav-social > button > img {
    width: 32px;
    height: 32px;
}
.logo-t{
    font-size: 20px;
}
.menu-overlay{
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    background-color: #1C202D;
    padding: 40px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;
}
.menu-overlay.active{
    display: block;
}
.menu-overlay > div > a,
.menu-overlay > div > button {
    margin-bottom: 16px;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.whitepaper {
    margin-bottom: 16px;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-close-btn{
    width: 35px;
    height: 35px;
    position: absolute;
    top: 38px;
    right: 11px;
}
.connect-btn{
    border-radius: 12px;
    padding: unset;
    height: 35px;
    font-size: 12px;
    width: 160px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    font-size: 12px !important;
}
.connect-btn.mobile{
    margin-top: 30px;
    border-bottom: unset;
    padding: 18px 32px;
    max-width: 252px;
    height: auto;
    border: 2px solid white;
    font-size: 18px;
    line-height: 140%;
}
.connect-btn > img{
    margin-left: 5px;
    width: 20px !important;
    height: 20px !important;
    margin-top: -4px;
}
.mobile-social-wrap{
    /* position: absolute;
    bottom: 320px; */
    margin-top: 100px;
}
.mobile-social-wrap > a{
    margin: 0 10px;
}
.mobile-social-wrap > a > img{
    width: 32px;
    height: 32px;
    object-fit: contain;
}
.nav-link.dropdown{
    position: relative;
}
.nav-link.dropdown:hover{
    filter: brightness(1) saturate(1);
    position: relative;
}
.nav-link.dropdown > div{
    display: none;
    position: absolute;
    top: 100%;
    background: #191A23;
    border: 1px solid rgba(242, 242, 242, 0.2);
    box-sizing: border-box;
    padding: 16px 12px;
    padding-right: 44px;
}
.nav-link.dropdown.active > div{
    display: flex;
}
.nav-link.dropdown > div > a{
    margin-bottom: 20px;
}
.nav-link.dropdown > div > a:last-child{
    margin-bottom: unset;
}
.nav-link.dropdown > div > a:hover{
    filter: brightness(0.8) saturate(0.7);
}
.nav-link.dropdown > img{
    width: 7px;
    height: 7px;
    margin-left: 8px;
    transition: all 200ms ease-out;
}
.nav-link.dropdown.active > img{
    transform: rotate(180deg);
}
.logo-wrap.mobile{
    position: absolute;
    top: 30px;
    left: 20px;
    width: auto;
    border-bottom: unset;
}
.nav-link.dropdown.mobile > .menu{
    position: relative;
    top: unset;
    background: unset;
    border: unset;
    padding: unset;
    padding-right: unset;
    margin-top: 20px;
    width: 100%;
    padding: 0 10px;
}
.nav-link.dropdown.mobile > span > img{
    width: 7px;
    height: 7px;
    margin-left: 8px;
    transition: all 200ms ease-out;
}
.nav-link.dropdown.active.mobile > span > img{
    transform: rotate(180deg);
}




@media all and (min-width: 1280px){
  
}
@media all and (min-width: 1440px){
  
}




@media all and (max-width: 1140px){
    .navbar > .container > .m-show{
        display: block;
    }
    .navbar > .container > .m-hide{
        display: none;
    }
}
@media all and (max-width: 1080px){
  
}
@media all and (max-width: 994px){
  
}
@media all and (max-width: 972px){
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){
    .navbar > .container{
        grid-template-columns: repeat(2, 1fr);
    }
    .navbar > .container > .logo-wrap > img{
        margin-right: unset;
        width: 100%;
    }
    .logo-t{
        display: none;
    }
}
@media all and (max-width: 568px){
    
}

@media all and (max-width: 430px){
  
}
@media all and (max-width: 320px){
  
}