.home-sec1 {
  background-color: #17171d;
  /* padding-top: 267px; */
  /* padding-top: 300px; */
  /* padding-bottom: 314px; */
  position: relative;
  /* margin-top: 111px; */
  height: 100vh;
  z-index: 5;
}

.home-sec1-h1 {
  width: 100%;
}

.home-sec1-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 0px;
  margin-bottom: 48px;
}

.home-sec1-grid>div>span:last-child {
  margin-top: 8px;
  color: var(--white-2);
}

.home-sec1-sub-email {
  width: 100%;
  max-width: 486px;
}

.home-sec1-sub-email>input {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(242, 242, 242, 0.2);
  box-sizing: border-box;
  backdrop-filter: blur(6px);
  border-radius: 4px;
  padding-left: 24px;
  padding-right: 60px;
  margin-right: -57px;
  height: 61px;
  flex: 1;
}

.home-sec1-sub-email>input::placeholder {
  color: var(--white-3);
}

.home-sec1-sub-email>button {
  background-color: var(--purple-1);
  height: 61px;
  /* padding: 0 24px; */
  border-radius: 12px;
  z-index: 2;
  width: 100%;
}

.home-sec1>.bg-vid {
  position: absolute;
  width: 100%;
  top: 0;
  /* left: 0; */
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}

.home-sec2 {
  padding-top: 128px;
}

.home-sec2>.container>.left-block {
  max-width: 467px;
  width: 100%;
  margin-right: 75px;
}

.home-sec2>.container>.left-block>h2 {
  margin-bottom: 24px;
}

.home-sec2>.container>.left-block>p {
  color: var(--white-2);
}

.home-sec2>.container>.right-block,
.home-sec3>.bg-wrap>.container>.left-block,
.home-sec4>.container>.right-block,
.home-sec5>.container>.right-block,
.home-sec6>.container>.left-block {
  flex: 1;
}

.home-sec2>.container>.right-block>video {
  width: 100%;
  background: url("../assets/sec1-frame1.png");
  padding: 57px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.home-sec2>.container>.right-block>img,
.home-sec4>.container>.right-block>img,
.home-sec5>.container>.right-block>img,
.home-sec6>.container>.left-block>img {
  width: 100%;
}

.home-sec3 {
  margin-top: 125px;
  position: relative;
}

.home-sec3>.bg-wrap {
  padding-bottom: 170px;
  padding-top: 150px;
  position: relative;
  z-index: 2;
}

.home-sec3>.bg-wrap::after {
  content: "";
  background-color: rgb(28, 31, 42);
  clip-path: polygon(0 1%, 100% 13%, 100% 100%, 0 87%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.home-sec3>.bg-wrap>.container>.left-block>img {
  width: 100%;
  transform: scale(1.7);
  position: relative;
  left: -192px;
  top: -146px;
}

.home-sec3>.bg-wrap>.container>.right-block {
  max-width: 511px;
  width: 100%;
}

.home-sec3>.bg-wrap>.container>.right-block>h2 {
  margin-bottom: 24px;
  margin-top: unset;
}

.home-sec3>.bg-wrap>.container>.right-block>p,
.home-sec4>.container>.left-block>p,
.home-sec5>.container>.left-block>p,
.home-sec6>.container>.right-block>p {
  opacity: 0.7;
}

.home-sec3>.bg-wrap>.container>.right-block>button,
.home-sec3-3>.container>.left>button {
  background: var(--purple-1);
  padding: 18px 48px;
  border-radius: 12px;
  margin-top: 48px;
  z-index: 22;
  position: relative;
}

.home-sec3>.bg-wrap>.container>.right-block>button:disabled,
.home-sec3-3>.container>.left>button:disabled,
.home-sec4>.container>.left-block>button:disabled {
  background: rgba(128, 128, 128, 0.5);
  padding: 18px 48px;
  border-radius: 12px;
  margin-top: 48px;
  z-index: 22;
  position: relative;
}

.home-sec3>.bg-wrap>.container>.right-block>button>img {
  margin-left: 12px;
}

.home-sec4 {
  /* margin-top: -100px; */
  position: relative;
  z-index: 6;
  background-color: rgb(25, 26, 35);
}

.home-sec4>.container {
  padding-top: 165px;
  padding-bottom: 139px;
  position: relative;
  z-index: 3;
}

.home-sec4>.container>.left-block {
  width: 100%;
  max-width: 575px;
}

.home-sec4>.container>.left-block>h2 {
  margin: unset;
  margin-bottom: 24px;
}

.home-sec4>.container>.left-block>button {
  margin-top: 40px;
  background: var(--purple-1);
  padding: 18px 48px;
  border-radius: 12px;
}

.home-sec4>.container>.left-block>button>img {
  margin-left: 12px;
}

.home-sec5>.container {
  padding-top: 120px;
}

.home-sec5>.container:last-child {
  padding-top: unset;
}

.home-sec5>.container>p,
.home-sec5>.container>div>p {
  color: rgba(255, 255, 255, 0.7);
}

.home-sec5>.container>.left-block {
  width: 100%;
  max-width: 504px;
  margin-right: 108px;
}

.home-sec6>.container {
  padding-top: 0px;
  padding-bottom: 80px;
}

.home-sec6>.container>.right-block {
  width: 100%;
  max-width: 532px;
  margin-left: 90px;
}

.home-sec7>.container {
  padding-top: 120px;
  padding-bottom: 80px;
}

.home-sec7>.container>img {
  width: 100%;
}

.home-sec8>.container {
  padding-bottom: 128px;
}

.home-sec8>.container>h2 {
  margin: unset;
  width: 100%;
}

.home-sec8>.container>img {
  width: 100%;
  max-width: 816px;
  height: 655px;
  object-fit: contain;
  margin-top: -69px;
}

/* .home-sec8 > .container > div > div{
    width: 50%;
} */
.home-sec8>.container>div>div>div {
  margin-bottom: 32px;
}

.home-sec8>.container>div>div>span {
  /* margin-bottom: 1em; */
  color: rgba(250, 250, 250, 0.7);
  /* line-height: 2em; */
  margin-bottom: 24px;
}

.home-sec9 {
  padding-bottom: 174px;
}

.home-sec9>.container>h2 {
  margin-bottom: 24px;
}

.road-item-h {
  margin-bottom: 24px;
}

.road-item-box {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  width: 1000px;
  padding: 32px;
  margin-right: 48px;
  min-height: 400px;
  height: 100%;
  position: relative;
  margin-bottom: 50px;
}

.road-item-box::after {
  content: "";
  background: url("../assets/road-itm-blur.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -40px;
  left: 0;
  z-index: -1;
}

.road-item-box>p {
  color: rgba(255, 255, 255, 0.7);
}

.road-indicator-wrap {
  margin-bottom: 38px;
}

.road-indicator {
  width: 18px;
  height: 18px;
  background: #ffffff;
  opacity: 0.23;
  border-radius: 4px;
  margin: 0 10px;
}

.road-indicator.active {
  background: #b92cdc;
  opacity: 1;
}

.road-arrow>img {
  margin: 0 12px;
  filter: saturate(0);
}

.road-arrow>img:first-child {
  transform: rotate(180deg);
}

.road-arrow>img:hover {
  filter: saturate(1);
}

.home-sec10>.container>.top-block {
  margin-bottom: 64px;
}

.home-sec10>.container>.top-block>p {
  opacity: 0.7;
  width: 100%;
  max-width: 799px;
}

.home-sec10>.container>.btm-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 32px;
}

.home-sec10>.container>.btm-block>.home-sec10-itm>img {
  width: 120px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 6px;
  overflow: hidden;
  object-fit: cover;
  margin-right: 24px;
}

.home-sec10>.container>.btm-block>.home-sec10-itm>div {
  flex: 1;
}

.home-sec10>.container>.btm-block>.home-sec10-itm>div>p {
  margin-top: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.home-sec11 {
  padding: 60px 0;
}

.home-sec11>.container>.right {
  flex: 1;
}

.home-sec11>.container>.right>img {
  width: 100%;
  object-fit: contain;
}

.home-sec11>.container>.left {
  max-width: 478px;
  width: 100%;
}

.home-sec11>.container>.left>p {
  margin-top: 24px;
  margin-bottom: 48px;
  color: rgba(255, 255, 255, 0.7);
}

.home-sec11>.container>.left>button,
.home-sec11>.container>.left>a {
  display: inline-block;
  background-color: var(--purple-1);
  height: 64px;
  padding: 0 48px;
  border-radius: 12px;
  z-index: 2;
}

.home-sec11>.container>.left>button>img,
.home-sec11>.container>.left>a>img {
  margin-left: 12px;
}

.home-dog-list {
  background: #000000;
  padding: 40px 32px;
  width: 100%;
  max-width: 486px;
  border-radius: 4px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.home-dog-list::before {
  content: "";
  width: 100%;
  height: 32px;
  top: -17px;
  left: -14px;
  z-index: -1;
  position: absolute;
  background: url("../assets/new/left-star-glow.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-dog-list::after {
  content: "";
  width: 100%;
  height: 32px;
  bottom: -15px;
  right: -14px;
  z-index: -1;
  position: absolute;
  background: url("../assets/new/right-star-glow.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-team-desc {
  max-height: 100px;
  overflow: hidden;
  transition: all 200ms ease-out;
}

.home-team-desc.active {
  max-height: 100%;
}

.home-team-desc>p {
  display: inline-block;
  vertical-align: top;
}

.home-team-desc.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 200ms ease;
}

.home-team-desc.clamp+button>.with-no-clamp {
  display: none;
}

.home-team-desc+button>.with-no-clamp {
  display: block;
}

.home-team-desc.clamp+button>.with-clamp {
  display: block;
}

.home-team-desc+button>.with-clamp {
  display: none;
}

.home-sec10-itm {
  user-select: none;
}

.home-sec10-itm.clamp>div>div>div>img {
  display: block;
}

.home-sec10-itm>div>div>div>img {
  display: none;
  transform: rotate(0deg);
  transition: transform 200ms ease-out;
}

.home-sec10-itm.active>div>div>div>img {
  transform: rotate(-180deg);
}

.home-sec5-video {
  max-width: 588px;
  width: 100%;
  height: 480px;
  object-fit: cover;
}

.home-sec3-1-itm {
  align-self: flex-start;
  max-width: 622px;
  padding: 24px;
  padding-right: 71px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 64px;
  position: relative;
  filter: opacity(0.3);
  transition: all 200ms ease-out;
}

.home-sec3-1-itm.active {
  filter: opacity(1);
}

.home-sec3-1-itm.right {
  align-self: flex-end;
}

.home-sec3-1-itm>img {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 40px;
}

.home-sec3-1-itm>div>p {
  opacity: 0.7;
  margin-top: 8px;
  margin-bottom: 24px;
}

.home-sec3-1-itm>div>a>img {
  margin-left: 10px;
}

.home-sec3-1-btn {
  margin-top: 24px;
  background: var(--purple-1);
  padding: 18px 48px;
  border-radius: 12px;
  margin-bottom: 135px;
}

.home-sec3-1-btn>img {
  margin-left: 12px;
}

.home-sec3-2-btn {
  margin-top: 48px;
  background: var(--purple-1);
  padding: 18px 48px;
  border-radius: 12px;
}

.home-sec3-2-btn>img {
  margin-left: 12px;
}

.indicator-slider>div {
  width: 16px;
  height: 16px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  margin: 0 8px;
}

.indicator-slider>div.active {
  background-color: var(--purple-1);
}

.dogonomic-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 101px;
  grid-row-gap: 0px;
}

.dogonomic-list>div>span>img {
  margin-right: 11px;
}

.faq-wrap {
  max-height: 677px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 40px;
}

.faq-item {
  background: rgba(0, 0, 0, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 40px;
  margin-bottom: 24px;
}

.faq-item>p {
  margin: unset;
  margin-top: 48px;
  opacity: 0.5;
  margin-bottom: 18px;
  display: none;
}

.home-sec3-1-itm-line {
  position: absolute;
  left: 100%;
  top: 50%;
}

.home-sec3-1-itm-line.reverse {
  right: 100%;
  left: unset;
  transform: rotateY(180deg);
}

.home-sec3-1-itm-line>.dotLine {
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: -8px;
  top: -6px;
  z-index: 3;
}

.home-sec3-1-itm>.home-sec3-1-itm-line>.dotLine>img,
.home-sec3-1-itm>.home-sec3-1-itm-line>.line {
  display: none;
}

.home-sec3-1-itm.active>.home-sec3-1-itm-line>.dotLine>img {
  display: block;
}

.slider-roadmap {
  /* width: 100vw;
    margin-left: -19vw; */
}

.slider-roadmap-wrap {
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  width: 268px;
  padding: 24px;
  margin-right: 83px;
  position: relative;
}

.slider-roadmap-wrap>h2 {
  margin: unset;
  margin-bottom: 24px;
}

.slider-roadmap-itm>img {
  display: block;
  z-index: 14;
  margin-bottom: -14px;
  position: relative;
}

.slider-roadmap-wrap>span {
  opacity: 0.7;
  margin-bottom: 1rem;
}

.slider-roadmap-wrap>span:last-child {
  margin-bottom: unset;
}

.roadmap-arrow>img:first-child {
  transform: rotate(180deg);
  filter: grayscale(1);
}

.roadmap-arrow>img:last-child {
  margin-left: 24px;
}

.faq-head>img {
  transform: rotate(0deg);
  transition: all 200ms ease;
}

.faq-head>img.active {
  transform: rotate(180deg);
}

.slider-alpha {
  max-width: 615px;
  margin-bottom: 40px;
}

.home-sec4-1>.container>.left {
  margin-right: 165px;
  max-width: 575px;
}

.home-sec4-2>.container>.right {
  max-width: 532px;
  margin-left: 64px;
}

.home-after-roadmap>.container>.mid {
  max-width: 343px;
}

.team-container {
  max-height: 392px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 51px;
}

.slider-roadmap .slick-list {
  overflow: visible;
}

.slider-roadmap-itm-dot {
  position: relative;
  margin-bottom: 40px;
  width: 16px;
  left: 40%;
}

.slider-roadmap-itm-dot>img {
  display: none;
}

.slider-roadmap-itm-dot.current>img {
  position: absolute;
  left: -92px;
  bottom: -108px;
  z-index: 7;
  display: block;
}

.slider-roadmap-itm-dot::before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;

  position: absolute;
  z-index: 5;
}

.slider-roadmap-itm-dot.active::before {
  background-color: rgba(185, 44, 220, 1);
}

.slider-roadmap-itm-dot.active::after {
  content: "";
  width: 50vw;
  height: 6px;
  position: absolute;
  top: 5px;
  right: 56%;
  background-color: white;
}

.roadmap-line {
  width: 100vw;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 0;
  top: 109px;
}

/* .roadmap-line::before{
    content:'';
    width: 20%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
} */
.home-sec3-2>.container>.right {
  margin-left: 64px;
}

.home-dog-list>div>.with-line {
  padding-top: 24px;
  position: relative;
}

.home-dog-list>div>.with-line::after {
  content: "";
  width: 90%;
  height: 2px;
  position: absolute;
  top: 0;
  left: 5%;
  background-color: rgba(255, 255, 255, 0.3);
}

.investors-container {
  margin: 0;
  margin-left: 8rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.featuredOn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media all and (min-width: 1280px) {}

@media all and (min-width: 1440px) {}

@media all and (max-width: 1140px) {}

@media all and (max-width: 1080px) {}

@media all and (max-width: 994px) {}

@media all and (max-width: 972px) {}

@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px) {
  .home-sec11>video {
    display: none;
  }

  .home-sec10>.container>.btm-block {
    grid-template-columns: repeat(1, 1fr);
  }

  .home-sec6>.container>.right-block {
    margin-left: unset;
  }

  .home-sec2>.container>.left-block,
  .home-sec5>.container>.left-block {
    margin-right: unset;
  }

  .home-sec6>.container>.left-block {
    margin-bottom: 80px;
  }

  .home-sec3>.bg-wrap>.container>.left-block>img {
    left: 0;
    transform: scale(1);
    width: 100%;
    top: -50px;
  }

  .home-sec3>.bg-wrap>.container>.right-block>button {
    margin-bottom: 50px;
  }

  .home-sec8>.container>div>div {
    width: 100%;
  }

  .home-sec8>.container>img {
    height: 400px;
  }

  .home-sec10>.container>.btm-block>.home-sec10-itm:first-child+.home-sec10-itm>img,
  .home-sec10>.container>.btm-block>.home-sec10-itm:first-child>img {
    /* margin: unset; */
    /* width: 100%; */
    /* margin-bottom: 20px; */
  }

  .road-item-box {
    width: 100%;
    margin-right: unset;
  }

  .home-sec10>.container>.btm-block>.home-sec10-itm>div {
    width: 100%;
  }

  .home-dog-list {
    margin-top: 40px;
  }

  .dogonomic-list>div {
    margin-bottom: 40px;
    width: 100%;
  }

  .dogonomic-list>div>span {
    margin-left: 20px;
  }

  .home-sec2>.container>.right-block>video {
    padding: 8vw;
  }

  .home-sec10>.container>.btm-block>.home-sec10-itm>img {
    /* height: 65vw; */
    margin: unset;
    /* width: 100%; */
    height: 64px;
    width: 64px;
    margin-bottom: 20px;
  }

  .home-bottom-vid {
    margin-top: 30px;
  }

  .home-sec11 {
    padding: 50px 0;
  }

  .home-sec5-video {
    height: 280px;
    margin-bottom: 40px;
  }

  .home-sec3-1-itm {
    max-width: 100%;
    padding-right: 24px;
  }

  .home-sec3-1-itm>img {
    width: 88px;
    height: 88px;
    margin-right: 16px;
  }

  .home-sec3-1-itm-line {
    left: 50%;
    top: 105%;
  }

  .home-sec3-1-itm-line.reverse {
    right: 50%;
    left: unset;
  }

  .home-sec3-1-itm.active>.home-sec3-1-itm-line>img,
  .home-sec3-1-itm>.home-sec3-1-itm-line>img {
    display: none;
  }

  .home-sec3-1-itm.active>.home-sec3-1-itm-line>.line,
  .home-sec3-1-itm>.home-sec3-1-itm-line>.line {
    display: block;
    left: -2px;
    position: relative;
    top: -6px;
  }

  .slider-alpha {
    max-width: 300px;
    width: 100%;
  }

  .home-sec3-2>.container {
    flex-direction: column;
    align-items: center;
  }

  .home-sec3-2>.container>.right {
    margin-left: unset;
    margin-top: 50px;
  }

  .home-sec4-1>.container>.left {
    max-width: 100%;
    margin-right: unset;
  }

  .home-sec4-2>.container>.right {
    max-width: 100%;
    margin-left: unset;
  }

  .dogonomic-list {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px;
  }

  .home-after-roadmap>.container>.mid {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .slider-roadmap-wrap {
    width: 95%;
  }

  .team-container {
    max-height: 500px;
    /* overflow-y: hidden;
        overflow-x: hidden;
        padding-right: unset; */
  }

  /* .faq-wrap {
        max-height: unset;
        overflow-y: hidden;
        overflow-x: hidden;
        padding-right: unset;
    } */
  .slider-roadmap-itm-dot.active::after {
    width: 100vw;
  }

  .roadmap-line {
    top: 106px;
  }

  .home-sec4-1>.container>.right {
    margin-top: 40px;
  }

  .investors-container {
    margin: 0;
    margin-top: 4rem;
    grid-template-columns: repeat(1, 1fr);
  }

  .featuredOn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

}

@media all and (max-width: 568px) {
  .roadmap-line {
    top: 104px;
  }
}

@media all and (max-width: 430px) {}

@media all and (max-width: 320px) {}