table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
  font-style: normal;
  font-weight: 400;
  text-align: start;
  padding: 0.5rem;
}

thead > tr > th {
  font-weight: 700;
}
