.blackout {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  backdrop-filter: blur(7px);
  display: none;
}
.blackout.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.44);
  max-width: 458px;
  padding: 20px 20px 57px 20px;
  position: relative;
}

.longbar {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
}
.longbar.active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media all and (min-width: 1280px) {
}
@media all and (min-width: 1440px) {
}

@media all and (max-width: 1140px) {
}
@media all and (max-width: 1080px) {
}
@media all and (max-width: 994px) {
}
@media all and (max-width: 972px) {
}
@media all and (max-width: 768px),
  all and (orientation: landscape) and (max-width: 768px) {
  .popup-container {
    max-width: 300px;
  }
  .longbar.active {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .longbar.active > div {
    text-align: center;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 568px) {
}

@media all and (max-width: 430px) {
}
@media all and (max-width: 320px) {
}
