.sec3-img{
    width: 100%;
    transform: scale(1.5);
    position: relative;
    left: -90px;
    -webkit-transform-origin-y: top;
}
.platform-img-wrap{
    width: 100%;
    flex-wrap: wrap;
}
.platform-img-wrap > img{
    width: auto;
    height: 56px;
    object-fit: contain;
    padding: 6px 0;
}
.mission-tab-btn{
    margin: 0 12px;
    opacity: 0.7;
}
.mission-tab-btn:last-child{
    margin-right: unset;
}
.mission-tab-btn:first-child{
    margin-left: unset;
}
.mission-tab-btn.active{
    padding: 10px 20px;
    opacity: 1;
    font-size: 22px;
    line-height: 150%;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    position: relative;
}
.mission-tab-btn.active::after{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.feature-sec1-img{
    width: 100%;
    transform: scale(1.4);
    position: relative;
    right: -40px;
}
.digital-dog-block{
    width: 100%;
    max-width: 468px;
    margin-left: 64px;
}
.feature-join-img{
    width: 100%;
    transform: scale(1.3);
    transform-origin: right;
}
.feature-platform-block{
    width: 52%;
    position: relative;
    z-index: 3;
}
.slideBase{
    width: 100%;
    /* height: 385.66px; */
    position: relative;
}
.wrapper-enjoy-sec{
    max-width: 467px;
    width: 100%;
    position: relative;
    z-index: 2;
}
.join-program-right{
    width: 100%;
    max-width: 384px;
    margin-left: 38px;
}




@media all and (min-width: 1280px){
  
}
@media all and (min-width: 1440px){
  
}




@media all and (max-width: 1140px){
}
@media all and (max-width: 1080px){
  
}
@media all and (max-width: 994px){
  
}
@media all and (max-width: 972px){
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){
    .feature-sec1-img{
        transform: scale(1);
        right: 0;
    }
    .digital-dog-block{
        margin-left: unset;
    }
    .sec3-img{
        transform: scale(1.3);
        left: unset;
        margin-bottom: 70px;
    }
    .feature-join-img{
        transform: scale(1);
        transform-origin: center;
    }
    .feature-platform-block{
        width: 100%;
    }
    .slideBase{
        margin-bottom: 50px;
    }
    .wrapper-enjoy-sec{
        max-width: auto;
    }
    .join-program-right{
        width: 100%;
        max-width: 384px;
        margin-left: unset;
    }
    .platform-img-wrap{
        width: 50%;
        align-items: flex-start;
    }
}
@media all and (max-width: 568px){
    
}

@media all and (max-width: 430px){
  
}
@media all and (max-width: 320px){
  
}