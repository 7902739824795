/* @font-face {
  font-family: "sf-regular";
  src: url("../src/assets/font/SFPRODISPLAYREGULAR.OTF");
}
@font-face {
  font-family: "sf-bold";
  src: url("../src/assets/font/SFPRODISPLAYBOLD.OTF");
}
@font-face {
  font-family: "sf-medium";
  src: url("../src/assets/font/SFPRODISPLAYMEDIUM.OTF");
} */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --white-1: white;
  --white-2: rgba(255, 255, 255, 0.7);
  --white-3: rgba(255, 255, 255, 0.5);

  --black-1: black;
  --black-2: rgba(25, 26, 35, 1);

  --purple-1: rgba(185, 44, 220, 1);
}
* {
  box-sizing: border-box;
  font-style: normal;
  color: var(--white-1);
  font-family: "Inter", sans-serif;
  /* scroll-behavior: smooth; */
}
body {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  margin: unset;
  background-color: var(--black-2);
}
#root {
  overflow-x: hidden;
  overflow-y: auto;
}
a {
  text-decoration: none;
}
button,
input,
select,
textarea {
  appearance: none;
  -webkit-appearance: none;
  border: unset;
  background-color: transparent;
  margin: unset;
  padding: unset;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.section {
  width: 100%;
  position: relative;
}
.container {
  max-width: 1440px;
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0 120px;
}
.cursor {
  cursor: pointer;
}
.no-margin {
  margin: unset;
}

.flex-r {
  display: flex;
  flex-direction: row;
}
.flex-r.v-center {
  align-items: center;
}
.flex-r.v-top {
  align-items: flex-start;
}
.flex-r.v-btm {
  align-items: flex-end;
}
.flex-r.h-center {
  justify-content: center;
}
.flex-r.h-left {
  justify-content: start;
}
.flex-r.h-right {
  justify-content: end;
}
.flex-r.just-space {
  justify-content: space-between;
}
.flex-c {
  display: flex;
  flex-direction: column;
}
.flex-c.h-center {
  align-items: center;
}
.flex-c.h-left {
  align-items: flex-start;
}
.flex-c.h-right {
  align-items: flex-end;
}
.flex-c.v-center {
  justify-content: center;
}
.flex-c.v-top {
  justify-content: start;
}
.flex-c.h-btm {
  justify-content: end;
}

.t-400 {
  font-weight: 400;
}
.t-500 {
  font-weight: 500;
}
.t-600 {
  font-weight: 600;
}
.t-700 {
  font-weight: 700;
}
.t-800 {
  font-weight: 800;
}

.t-14-19 {
  font-size: 14px;
  line-height: 140%;
}
.t-16-22 {
  font-size: 16px;
  line-height: 140%;
}
.t-18-25 {
  font-size: 18px;
  line-height: 140%;
}
.t-20-28 {
  font-size: 20px;
  line-height: 140%;
}
.t-22-33 {
  font-size: 22px;
  line-height: 150%;
}
.t-24-33 {
  font-size: 24px;
  line-height: 140%;
}
.t-26-32 {
  font-size: 26px;
  line-height: 32px;
}
.t-32-41 {
  font-size: 32px;
  line-height: 130%;
}
.t-40-52 {
  font-size: 40px;
  line-height: 130%;
}
.t-48-57 {
  font-size: 48px;
  line-height: 120%;
}
.t-77-77 {
  font-size: 77px;
  line-height: 100%;
}

.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.t-left {
  text-align: left;
}

.white-05 {
  color: rgba(255, 255, 255, 0.5);
}
.white-07 {
  color: rgba(255, 255, 255, 0.7);
}

.purple {
  color: var(--purple-1);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.8);
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.dot {
  display: list-item;
}
.dot-r {
  position: relative;
  margin-left: 20px;
}
.dot-r::after {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  left: -11px;
  top: 9px;
  position: absolute;
}
.hover-btn:hover {
  filter: brightness(0.8) saturate(0.7);
}
.m-show {
  display: none;
}

@media all and (min-width: 1280px) {
}
@media all and (min-width: 1440px) {
}

@media all and (max-width: 1140px) {
  .container {
    padding: 0 60px;
  }
}
@media all and (max-width: 1080px) {
  .container {
    padding: 0 40px;
  }
}
@media all and (max-width: 994px) {
}
@media all and (max-width: 972px) {
}
@media all and (max-width: 768px),
  all and (orientation: landscape) and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
  .m-hide {
    display: none;
  }
  .m-show {
    display: unset;
  }
  .flex-r.mobile-c {
    flex-direction: column;
  }

  .t-14-19 {
    font-size: 12px;
    line-height: 140%;
  }
  .t-16-22 {
    font-size: 14px;
    line-height: 140%;
  }
  .t-18-25 {
    font-size: 16px;
    line-height: 140%;
  }
  .t-20-28 {
    font-size: 18px;
    line-height: 140%;
  }
  .t-22-33 {
    font-size: 20px;
    line-height: 150%;
  }
  .t-24-33 {
    font-size: 22px;
    line-height: 140%;
  }
  .t-26-32 {
    font-size: 24px;
    line-height: 32px;
  }
  .t-32-41 {
    font-size: 30px;
    line-height: 130%;
  }
  .t-40-52 {
    font-size: 38px;
    line-height: 130%;
  }
  .t-48-57 {
    font-size: 46px;
    line-height: 120%;
  }
  .t-77-77 {
    font-size: 75px;
    line-height: 100%;
  }
}
@media all and (max-width: 568px) {
  .t-14-19 {
    font-size: 10px;
    line-height: 140%;
  }
  .t-16-22 {
    font-size: 12px;
    line-height: 140%;
  }
  .t-18-25 {
    font-size: 14px;
    line-height: 140%;
  }
  .t-20-28 {
    font-size: 16px;
    line-height: 140%;
  }
  .t-22-33 {
    font-size: 18px;
    line-height: 150%;
  }
  .t-24-33 {
    font-size: 20px;
    line-height: 140%;
  }
  .t-26-32 {
    font-size: 22px;
    line-height: 32px;
  }
  .t-32-41 {
    font-size: 28px;
    line-height: 130%;
  }
  .t-40-52 {
    font-size: 36px;
    line-height: 130%;
  }
  .t-48-57 {
    font-size: 44px;
    line-height: 120%;
  }
  .t-77-77 {
    font-size: 73px;
    line-height: 100%;
  }
}

@media all and (max-width: 430px) {
}
@media all and (max-width: 320px) {
}
