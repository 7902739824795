.tech-sec1{
    padding-top: 168px;
    background-color: rgba(25, 26, 35, 1);
    padding-bottom: 130px;
}
.tech-sec1 > .container > h1,
.tech-sec2 > .container > h1,
.tech-sec3 > .container > h1{
    width: 100%;
    margin: unset;
    margin-bottom: 32px;
}
.tech-sec1 > .container > p,
.tech-sec2 > .container > p,
.tech-sec2 > .container > .grid > .grid-itm > p,
.tech-sec3 > .container > .wrap > p{
    color: rgba(255, 255, 255, 0.7);
}
.tech-sec1 > .container > img{
    width: 100%;
    max-width: 700px;
    margin-top: 130px;
}


.tech-sec2 > .container > img{
    max-width: 1056px;
    width: 100%;
    margin-top: 56px;
    margin-bottom: 104px;
}
.tech-sec2 > .container > .grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 32px;
    margin-bottom: 80px;
}
.tech-sec2 > .container > .grid > .grid-itm > p{
    margin-top: 16px;
}


.tech-sec3{
    margin-bottom: 168px;
    margin-top: 168px;
}
.tech-sec3 > .container > .wrap{
    max-width:340px;
    margin-right: 170px;
}
/* .tech-sec3 > .container > img{
    width: 100%;
}
.tech-sec3 > .container > .wrap{
    width: 100%;
    max-width: 942px;
    margin-top: 168px;
}
.tech-sec3 > .container > .wrap > h1{
    max-width: 306px;
    width: 100%;
    margin:unset;
    margin-right: 48px;
}
.tech-sec3 > .container > .wrap > p{
    flex:1;
} */
.tech-interact-right{
    width: 45%;
    margin-left: 50px;
}
.tech-interact-img{
    width: 100%;
    position: relative;
    transform: scale(1.3);
    transform-origin: right;
}
.tech-form{
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.tech-form > div{
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.tech-form > div:last-child{
    border-bottom: unset;
    min-height: 200px;
}
.tech-form > div > div{
    width: 100%;
    max-width: 168px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px 16px;
}
.tech-form > div > input,
.tech-form > div > textarea{
    padding: 12px 16px;
    flex: 1;
}
.tech-form + button,
.purple-btn{
    margin-top: 40px;
    background: var(--purple-1);
    padding: 18px 48px;
    border-radius: 12px;
}
.tech-form + button > img,
.purple-btn > img{
    margin-left: 12px;
}




@media all and (min-width: 1280px){
  
}
@media all and (min-width: 1440px){
  
}




@media all and (max-width: 1140px){
}
@media all and (max-width: 1080px){
  
}
@media all and (max-width: 994px){
  
}
@media all and (max-width: 972px){
}
@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px){
    .tech-sec2 > .container > .grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .tech-sec3 > .container > .wrap > h1{
        margin-right: unset;
    }
    .tech-sec3 > .container > .wrap{
        align-items: flex-start;
    }
    .tech-interact-right{
        width: 100%;
        margin-left: unset;
    }
    .tech-interact-img{
        transform: scale(1);
        transform-origin: center;
    }
    .tech-sec3 > .container > .wrap{
        max-width:100%;
        margin-right: unset;
        margin-bottom: 20px;
    }
    .tech-form > div > input,
    .tech-form > div > textarea{
        min-width: 50vw;
    }
}
@media all and (max-width: 568px){
    
}

@media all and (max-width: 430px){
  
}
@media all and (max-width: 320px){
  
}