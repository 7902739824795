.footer {
    background-color: rgba(26, 28, 38, 1);
    padding-top: 24px;
    padding-bottom: 32px;
    /* 96px cause otherwise it'll be covered by kickoff widget */
    /* padding-bottom: 96px; */
}

.footer>.container>.top {
    margin-bottom: 30px;
}

.footer>.container>.top>img {
    max-width: 410.7px;
    width: 100%;
}

.footer>.container>.top>.link-wrap>a {
    margin-left: 80px;
    color: rgba(255, 253, 253, 0.7);
}

.footer>.container>.btm>.copy {
    color: rgba(255, 253, 253, 0.7);
}

.footer>.container>.btm>.social-wrap>a {
    margin-left: 20px;
}

.footer>.container>.btm>.social-wrap>a>img {
    width: 32px;
    height: 32px;
}




@media all and (min-width: 1280px) {}

@media all and (min-width: 1440px) {}




@media all and (max-width: 1140px) {}

@media all and (max-width: 1080px) {}

@media all and (max-width: 994px) {}

@media all and (max-width: 972px) {}

@media all and (max-width: 768px),
all and (orientation: landscape) and (max-width: 768px) {
    .footer>.container>.btm>.copy {
        text-align: center;
        margin-bottom: 30px;
        align-items: center;
    }

    .footer>.container>.top>.link-wrap>a {
        margin: 0 15px;
    }

    .footer>.container>.btm {
        align-items: center;
    }
}

@media all and (max-width: 568px) {}

@media all and (max-width: 430px) {}

@media all and (max-width: 320px) {}